import React from 'react';
import Costomer from './Costomer/Costomer';
import CarouselSlider from './Crousal/Crousal'
import Result from './Result';

const CostomerMain = () => {
  return (
    <div>
    <Costomer/>
    {/* <CarouselSlider/> */}
    <Result/>

       
    </div>
  )
}

export default CostomerMain
