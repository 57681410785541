import React from 'react'
import Nav from '../Navbar/Navbar'
import Fotter from '../Componenet/Fotter/Fotter';
const Company = () => {
  return (
    <div>
      <Nav />
      <Fotter />
    </div>
  )
}

export default Company
