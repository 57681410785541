import React from 'react';
import Nav from '../Navbar/Navbar'
import Fotter from '../Componenet/Fotter/Fotter';

const Pricing = () => {
  return (
    <div>
        <Nav />
        <Fotter />
    </div>
  )
}

export default Pricing
