import React from 'react';
import Nav from '../Navbar/Navbar'
import Fotter from '../Componenet/Fotter/Fotter';

function Products() {
  return (
    <div>
       <Nav />  
      <Fotter /> 
    </div>
  )
}

export default Products
